import React, { useContext } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import { IMaskInput } from 'react-imask';
import { formatDatePtBr } from './../utils/format';
import { convertDataForPgSql } from './../utils/format'
import SaleSection from '../components/SaleSection';
import { useState } from 'react';
import axios from 'axios';
import Loader from '../components/small_components/Loader';
import NewAddressModal from '../components/modals/NewAddressModal';
import MainContext from '../context/MainContext';
import MyAddressModal from '../components/modals/MyAddressModal';
import AddressContext from '../context/AddressContext';
import AlterPasswordModal from '../components/modals/AlterPasswordModal';
import { Toast } from './../utils/toast';

function Profile() {
  const navigate = useNavigate();

  const { user, setUser, token, isOpenAlterPasswordModal, setIsOpenAlterPasswordModal } = useContext(AuthContext);
  const { setIsUpdate } = useContext(AddressContext);
  const { isOpenAddressModal, setIsOpenAddressModal, isOpenMyAddressModal, setIsOpenMyAddressModal } = useContext(MainContext);
  const [isActive, setIsActive] = useState(false);

  const [firstName, setFirstName] = useState(user?.firstName || '');
  const [lastName, setLastName] = useState(user?.lastName || '');
  const [email, setEmail] = useState(user?.email || '');
  const [document, setDocument] = useState(user?.cpf || '');
  const [birthday, setBirthday] = useState(user?.birthday || '');
  const [phone, setPhone] = useState(user?.phone || '');

  const [cep, setCep] = useState(user?.mainAddress?.cep || '');
  const [street, setStreet] = useState(user?.mainAddress?.street || '')
  const [number, setNumber] = useState(user?.mainAddress?.number || '');
  const [complement, setComplement] = useState(user?.mainAddress?.complement || '');
  const [district, setDistrict] = useState(user?.mainAddress?.district || '');
  const [city, setCity] = useState(user?.mainAddress?.city || '');
  const [state, setState] = useState(user?.mainAddress?.state || '');
  const [reference, setReference] = useState(user?.mainAddress?.reference || '');

  const [toAlterUserData, setToAlterUserData] = useState(false);
  const [toAlterAddressData, setToAlterAddressData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [hasError, setHasError] = useState({
    status: false,
    title: '',
    msg: ''
  });
  const [hasSuccess, setHasSuccess] = useState({
    status: false,
    title: '',
    msg: ''
  });

  async function fetchDataViaZipCode(e) {
    e.preventDefault();

    setDistrict("");
    setCity("");
    setStreet("");
    setState("");

    if (cep !== '') {
      axios.get(`https://viacep.com.br/ws/${cep.replace(/[.-]/g, "")}/json/`)
        .then((res) => {
          setDistrict(res.data.bairro);
          setCity(res.data.localidade);
          setStreet(res.data.logradouro);
          setState(res.data.uf);

          if (res.data.hasOwnProperty('erro')) {
            Toast.fire({
              icon: 'error',
              title: 'NÃO FOI POSSÍVEL ENCONTRAR O CEP.',
            })
          } else {
            Toast.fire({
              icon: 'success',
              title: 'CEP ENCONTRADO.',
            })
          }
        })
        .catch((err) => {
          Toast.fire({
            icon: 'error',
            title: 'NÃO FOI POSSÍVEL ENCONTRAR O CEP.',
          })
        })
    } else {
      Toast.fire({
        icon: 'info',
        title: 'INFORME O CEP PARA BUSCAR.',
      })
    }
  }

  const handleClickSave = async (e) => {
    e.preventDefault();
    setIsLoading(true);
console.log(user);
    try {
      if (toAlterUserData && toAlterAddressData) {
        const response = await axios.put('https://api.buquemarque.com.br/api/users/' + user.id, {
          firstName: firstName,
          lastName: lastName,
          email: email,
          document: document,
          birthday: birthday,
          phone: phone,
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        });

        if (response.status === 200) {
          // Atualizando o valor no state do context + no localstorage (User)
          setUser({ id: user.id, firstName: response.data.data.firstName, lastName: response.data.data.lastName, email: response.data.data.email, birthday: response.data.data.birthday, cpf: response.data.data.cpf, phone: response.data.data.phone, profilePictureUrl: response.data.data.profilePictureUrl, adresses: user.adresses, mainAddress: user.mainAddress });
          localStorage.setItem('app-mercado-unido-user', JSON.stringify({ id: user.id, firstName: response.data.data.firstName, lastName: response.data.data.lastName, email: response.data.data.email, birthday: response.data.data.birthday, cpf: response.data.data.cpf, phone: response.data.data.phone, profilePictureUrl: response.data.data.profilePictureUrl, adresses: user.adresses, mainAddress: user.mainAddress }));

          const responseAddress = await axios.put('https://api.buquemarque.com.br/api/adresses/' + user.mainAddress.id, {
            street: street,
            district: district,
            number: number,
            cep: cep,
            complement: complement,
            reference: reference,
            city: city,
            state: state,
          }, {
            headers: {
              'Authorization': `Bearer ${token}`,
            }
          });

          if (responseAddress.status === 200) {
            setUser({ id: user.id, firstName: response.data.data.firstName, lastName: response.data.data.lastName, email: response.data.data.email, birthday: response.data.data.birthday, cpf: response.data.data.cpf, phone: response.data.data.phone, profilePictureUrl: response.data.data.profilePictureUrl, adresses: user.adresses, mainAddress: responseAddress.data.data });
            localStorage.setItem('app-mercado-unido-user', JSON.stringify({ id: user.id, firstName: response.data.data.firstName, lastName: response.data.data.lastName, email: response.data.data.email, birthday: response.data.data.birthday, cpf: response.data.data.cpf, phone: response.data.data.phone, profilePictureUrl: response.data.data.profilePictureUrl, adresses: user.adresses, mainAddress: responseAddress.data.data }));

            setHasSuccess({
              status: true,
              title: 'Seus dados foram alterados!',
              msg: 'Parabéns, você alterou seus dados com sucesso.'
            })


          } else {
            setHasError({
              status: true,
              title: 'Ocorreu um erro inesperado!',
              msg: 'Não foi possivél alterar seus dados de endereço, por favor, tente novamente mais tarde.'
            })
          }
        } else {
          setHasError({
            status: true,
            title: 'Ocorreu um erro inesperado!',
            msg: 'Não foi possivél alterar seus dados pessoais, por favor, tente novamente mais tarde.'
          })
        }
      } else if (toAlterUserData) {
        const response = await axios.put('https://api.buquemarque.com.br/api/users/' + user.id, {
          firstName: firstName,
          lastName: lastName,
          email: email,
          document: document,
          birthday: birthday,
          phone: phone,
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        });

        if (response.status === 200) {
          // Atualizando o valor no state do context + no localstorage (User)
          setUser({ id: user.id, firstName: response.data.data.firstName, lastName: response.data.data.lastName, email: response.data.data.email, birthday: response.data.data.birthday, cpf: response.data.data.cpf, phone: response.data.data.phone, profilePictureUrl: response.data.data.profilePictureUrl, adresses: user.adresses, mainAddress: user.mainAddress });
          localStorage.setItem('app-mercado-unido-user', JSON.stringify({ id: user.id, firstName: response.data.data.firstName, lastName: response.data.data.lastName, email: response.data.data.email, birthday: response.data.data.birthday, cpf: response.data.data.cpf, phone: response.data.data.phone, profilePictureUrl: response.data.data.profilePictureUrl, adresses: user.adresses, mainAddress: user.mainAddress }));

          setHasSuccess({
            status: true,
            title: 'Seus dados foram alterados!',
            msg: 'Parabéns, você alterou seus dados com sucesso.'
          })
        } else {
          setHasError({
            status: true,
            title: 'Ocorreu um erro inesperado!',
            msg: 'Não foi possivél alterar seus dados pessoais, por favor, tente novamente mais tarde.'
          })
        }
      } else if (toAlterAddressData) {
        const responseAddress = await axios.put('https://api.buquemarque.com.br/api/adresses/' + user?.mainAddress?.id, {
          street: street,
          district: district,
          number: number,
          cep: cep,
          complement: complement,
          reference: reference,
          city: city,
          state: state,
        }, {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        });

        if (responseAddress.status === 200) {
          setUser({ id: user.id, firstName: user.firstName, lastName: user.lastName, email: user.email, birthday: user.birthday, cpf: user.cpf, phone: user.phone, profilePictureUrl: user.profilePictureUrl, adresses: user.adresses, mainAddress: responseAddress.data.data });
          localStorage.setItem('app-mercado-unido-user', JSON.stringify({ id: user.id, firstName: user.firstName, lastName: user.lastName, email: user.email, birthday: user.birthday, cpf: user.cpf, phone: user.phone, profilePictureUrl: user.profilePictureUrl, adresses: user.adresses, mainAddress: responseAddress.data.data }));

          setHasSuccess({
            status: true,
            title: 'Seus dados foram alterados!',
            msg: 'Parabéns, você alterou seus dados com sucesso.'
          })
        } else {
          setHasError({
            status: true,
            title: 'Ocorreu um erro inesperado!',
            msg: 'Não foi possivél alterar seus dados de endereço, por favor, tente novamente mais tarde.'
          })
        }
      }
    } catch (error) {
      setHasError({
        status: true,
        title: 'Ocorreu um erro inesperado!',
        msg: 'Pedimos desculpas pelo ocorrido, estamos trabalhando para corrigir.'
      })

      console.log(error)
    } finally {
      setIsLoading(false);
      window.scrollTo(0, 0);
    }
  }

  return (
    <>
      {
        (isLoading) && (
          <Loader />
        )
      }
      {
        (isOpenAddressModal) && (
          <NewAddressModal />
        )
      }
      {
        (isOpenMyAddressModal) && (
          <MyAddressModal />
        )
      }
      {
        (isOpenAlterPasswordModal) && (
          <AlterPasswordModal />
        )
      }
      <Header />
      <section className="bg-background-gray py-10">
        {
          (hasSuccess.status) ? (
            <div class="mx-auto bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md max-w-screen-lg mb-[10px]" role="alert">
              <div class="flex">
                <div class="py-1"><svg class="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg></div>
                <div>
                  <p class="font-bold">{hasSuccess.title}</p>
                  <p class="text-sm">{hasSuccess.msg}</p>
                </div>
              </div>
            </div>
          ) : (
            (hasError.status) && (
              <div class="mx-auto bg-red-100 border-t-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow-md max-w-screen-lg mb-[10px]" role="alert">
                <div class="flex">
                  <div class="py-1"><svg class="fill-current h-6 w-6 text-red-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg></div>
                  <div>
                    <p class="font-bold">{hasError.title}</p>
                    <p class="text-sm">{hasError.msg}</p>
                  </div>
                </div>
              </div>
            )
          )
        }
        <article className="bg-white mx-auto rounded-md w-5/6 shadow-md px-8 py-12 lg:pb-[130px] lg:pt-[38px] lg:px-[90px] max-w-screen-lg">
          <div className='flex flex-row items-center hover:cursor-pointer hover:opacity-80' onClick={() => navigate(-1)}>
            <AiOutlineArrowLeft className='text-primary-blue font-bold' />
            <p className='px-2 text-primary-blue underline'>Voltar</p>
          </div>
          <h3 className="text-primary-blue text-left w-full font-bold text-[35px]">Meu Perfil</h3>
          <p className="text-secondary-dark-gray text-[18px]">
            Gerencie seus dados pessoais.
          </p>
          <form>
            <div className="mt-[60px] grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 pb-8">
              <div className="sm:col-span-3">
                <label className="block text-sm font-bold text-[16px] text-primary-blue">
                  Nome
                </label>
                <div className="mt-1">
                  <input className="block w-full rounded-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                    type="text"
                    value={firstName}
                    maxLength="255"
                    onChange={(e) => {
                      if (!isActive) setIsActive(true);
                      if (!toAlterUserData) setToAlterUserData(true);
                      setFirstName(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label className="block text-sm font-bold text-[16px] text-primary-blue">
                  Sobrenome
                </label>
                <div className="mt-1">
                  <input className="block w-full rounded-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                    type="text"
                    value={lastName}
                    maxLength="255"
                    onChange={(e) => {
                      if (!isActive) setIsActive(true);
                      if (!toAlterUserData) setToAlterUserData(true);
                      setLastName(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label className="block text-sm font-bold text-[16px] text-primary-blue">
                  E-mail
                </label>
                <div className="mt-1">
                  <input className="block w-full rounded-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                    type="text"
                    value={email}
                    maxLength="255"
                    onChange={(e) => {
                      if (!isActive) setIsActive(true);
                      if (!toAlterUserData) setToAlterUserData(true);
                      setEmail(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label className="block text-sm font-bold text-[16px] text-primary-blue">
                  CPF
                </label>
                <div className="mt-1">
                  <IMaskInput
                    value={document}
                    mask='000.000.000-00'
                    type="text"
                    onChange={(e) => {
                      if (!isActive) setIsActive(true);
                      if (!toAlterUserData) setToAlterUserData(true);
                      setDocument(e.target.value.replace(/[^0-9]/g, ''));
                    }}
                    className="block w-full rounded-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label className="block text-sm font-bold text-[16px] text-primary-blue">
                  Data de Nascimento
                </label>
                <div className="mt-1">
                  <IMaskInput
                    value={formatDatePtBr(birthday)}
                    mask='00/00/0000'
                    type="text"
                    onChange={(e) => {
                      if (!isActive) setIsActive(true);
                      if (!toAlterUserData) setToAlterUserData(true);
                      setBirthday(convertDataForPgSql(e.target.value));
                    }}
                    className="block w-full rounded-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                  />
                </div>
              </div>


              <div className="sm:col-span-3">
                <label className="block text-sm font-bold text-[16px] text-primary-blue">
                  Telefone
                </label>
                <div className="mt-1">
                  <IMaskInput
                    value={formatDatePtBr(phone)}
                    mask='(00) 00000-0000'
                    type="text"
                    onChange={(e) => {
                      if (!isActive) setIsActive(true);
                      if (!toAlterUserData) setToAlterUserData(true);
                      setPhone(e.target.value.replace(/[^0-9]/g, ''));
                    }}
                    className="block w-full rounded-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label className="block text-sm font-bold text-[16px] text-primary-blue">
                  Senha (mínimo 6 caracteres)
                </label>
                <div className="mt-1">
                  <input className="block w-full rounded-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                    type="text"
                    value="**************"
                    maxLength="255"
                    disabled
                  />
                  <p onClick={(e) => setIsOpenAlterPasswordModal(true)} className="underline pt-[3px] text-[12px] text-primary-blue cursor-[pointer]">Alterar Senha</p>
                </div>

                <div className="">
                  <div className="flex items-center justify-left pt-[30px]">
                    <span className="h-auto w-[80px] lg:w-[120px] overflow-hidden rounded-full bg-gray-100">
                      {user?.profilePictureUrl ? (
                        <img src={user?.profilePictureUrl} alt="Avatar" className="h-full w-full" />
                      ) : (
                        <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      )}
                    </span>
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer font-bold text-[16px] border-[2px] border-gray-500 bg-white rounded-md px-[28px] py-1 text-primary-blue duration-150 opacity-60 mx-[12px]"
                    >
                      <span>Adicionar foto</span>
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        className="sr-only"
                      />
                    </label>
                  </div>
                </div>
              </div>

              <div className="sm:col-span-6 text-left">
                <h3 className="mt-[33px] mb-[20px] text-primary-blue text-left w-full font-bold text-[30px]">Endereço de Entrega</h3>
                <p className="text-secondary-dark-gray text-[18px]">
                  Gerencie os dados do seu endereço <b>principal</b>.
                </p>
                <button onClick={(e) => {setIsOpenAddressModal(true); setIsUpdate(false);}} className='pr-[10px] text-[16px] text-gray-400 underline' type="button">
                  Adicionar Novo Endereço
                </button>
                <button onClick={(e) => setIsOpenMyAddressModal(true)} className='text-[16px] text-gray-400 underline' type="button">
                  Meus Endereços
                </button>
              </div>

              <div className="sm:col-span-4 text-left">
                <label className="block text-sm font-bold text-[16px] text-primary-blue">
                  CEP
                </label>
                <div className="mt-1 flex rounded-md shadow-sm mb-[20px]">
                  <div className="relative flex flex-grow items-stretch focus-within:z-10">
                    <IMaskInput
                      value={cep}
                      mask='00.000-000'
                      type="text"
                      onChange={(e) => {
                        if (!isActive) {
                          setIsActive(true);
                        }

                        if (!toAlterAddressData) {
                          setToAlterAddressData(true);
                        }

                        setCep(e.target.value.replace(/[^0-9]/g, ''));
                      }}
                      className="block w-full rounded-l-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                    />
                  </div>
                  <button
                    onClick={(e) => fetchDataViaZipCode(e)}
                    type="button"
                    className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-primary-blue bg-primary-blue px-4 py-2 text-sm font-medium text-white hover:opacity-80"
                  >
                    OK
                  </button>
                </div>
                <a target='_blank' href="https://buscacepinter.correios.com.br/app/endereco/index.php" rel="noreferrer" className='text-[16px] text-gray-400 underline '>Não sei meu CEP</a>
              </div>
            </div>

            <div className="mt-[-6px] grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 pb-8">

              <div className="sm:col-span-3">
                <label className="block text-sm font-bold text-[16px] text-primary-blue">
                  Endereço
                </label>
                <div className="mt-1">
                  <input className="block w-full rounded-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                    type="text"
                    value={street}
                    onChange={(e) => {
                      if (!isActive) {
                        setIsActive(true);
                      }

                      if (!toAlterAddressData) {
                        setToAlterAddressData(true);
                      }

                      setStreet(e.target.value);
                    }}
                    maxLength="255"
                  />
                </div>
              </div>

              <div className="sm:col-span-1">
                <label className="block text-sm font-bold text-[16px] text-primary-blue">
                  Número
                </label>
                <div className="mt-1">
                  <input className="block w-full rounded-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                    type="text"
                    value={number}
                    onChange={(e) => {
                      if (!isActive) {
                        setIsActive(true);
                      }

                      if (!toAlterAddressData) {
                        setToAlterAddressData(true);
                      }

                      setNumber(e.target.value);
                    }}
                    maxLength="255"
                  />
                </div>
              </div>

              <div className="sm:col-span-2">
                <label className="block text-sm font-bold text-[16px] text-primary-blue">
                  Complemento
                </label>
                <div className="mt-1">
                  <input className="block w-full rounded-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                    type="text"
                    value={complement}
                    onChange={(e) => {
                      if (!isActive) {
                        setIsActive(true);
                      }

                      if (!toAlterAddressData) {
                        setToAlterAddressData(true);
                      }

                      setComplement(e.target.value);
                    }}
                    maxLength="255"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label className="block text-sm font-bold text-[16px] text-primary-blue">
                  Bairro
                </label>
                <div className="mt-1">
                  <input className="block w-full rounded-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                    type="text"
                    value={district}
                    onChange={(e) => {
                      if (!isActive) {
                        setIsActive(true);
                      }

                      if (!toAlterAddressData) {
                        setToAlterAddressData(true);
                      }

                      setDistrict(e.target.value);
                    }}
                    maxLength="255"
                  />
                </div>
              </div>

              <div className="sm:col-span-2">
                <label className="block text-sm font-bold text-[16px] text-primary-blue">
                  Cidade
                </label>
                <div className="mt-1">
                  <input className="block w-full rounded-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                    type="text"
                    value={city}
                    onChange={(e) => {
                      if (!isActive) {
                        setIsActive(true);
                      }

                      if (!toAlterAddressData) {
                        setToAlterAddressData(true);
                      }

                      setCity(e.target.value);
                    }}
                    maxLength="255"
                  />
                </div>
              </div>

              <div className="sm:col-span-1">
                <label className="block text-sm font-bold text-[16px] text-primary-blue">
                  Estado
                </label>
                <div className="mt-1">
                  <input className="block w-full rounded-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                    type="text"
                    value={state}
                    onChange={(e) => {
                      if (!isActive) {
                        setIsActive(true);
                      }

                      if (!toAlterAddressData) {
                        setToAlterAddressData(true);
                      }

                      setState(e.target.value);
                    }}
                    maxLength="255"
                  />
                </div>
              </div>

              <div className="sm:col-span-3">
                <label className="block text-sm font-bold text-[16px] text-primary-blue">
                  Ponto de referência
                </label>
                <div className="mt-1">
                  <input className="block w-full rounded-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                    type="text"
                    value={reference}
                    onChange={(e) => {
                      if (!isActive) {
                        setIsActive(true);
                      }

                      if (!toAlterAddressData) {
                        setToAlterAddressData(true);
                      }

                      setReference(e.target.value);
                    }}
                    maxLength="255"
                  />
                </div>
              </div>

            </div>

            <div className='mt-4 w-full flex flex-row items-center justify-left'>
              <button className={`${(isActive) ? 'hover:bg-red-600 hover:text-white hover:border-red-600' : 'cursor-not-allowed opacity-50'} w-[80px] md:w-[120px] text-sm bg-white border-[1px] border-gray-400 text-gray-600 py-2 rounded-md w-full shadow-md duration-150 font-medium mx-1`}>
                Cancelar
              </button>
              <button
                onClick={(e) => handleClickSave(e)}
                className={`${(isActive) ? 'hover:bg-primary-blue hover:text-white' : 'cursor-not-allowed opacity-50'} w-[80px] md:w-[120px] text-sm bg-white border-[1px] border-primary-blue text-primary-blue py-2 rounded-md w-full shadow-md duration-150 font-medium mx-1`}>
                Salvar
              </button>
            </div>
          </form>
        </article>
        <SaleSection category="ending_sales" title="Ofertas mais próximas de expirar" />
      </section>
      <Footer />
    </>

  )
}

export default Profile;