import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as auth from './../services/auth.js';
import * as cart from './../services/cart.js';
import * as order from './../services/order.js';
import * as address from './../services/address.js';
import * as users from './../services/user.js';
import AuthContext from './AuthContext';

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();

    const [user, setUser] = useState(null);
    const [mainAddress, setMainAddress] = useState(null);
    const [token, setToken] = useState(null);
    const [isLogged, setIsLogged] = useState(false);
    const [shoppingCart, setShoppingCart] = useState([]);
    const [orders, setOrders] = useState([]);
    const [checkoutItem, setCheckoutItem] = useState({});
    const [idCart, setIdCart] = useState('');

    useEffect(() => {
        const token = localStorage.getItem('app-mercado-unido-token');
        const user = localStorage.getItem('app-mercado-unido-user');
        const isLogged = localStorage.getItem('app-mercado-unido-logged');
        const shoppingCart = localStorage.getItem('app-mercado-unido-cart');
        const orders = localStorage.getItem('app-mercado-unido-orders');

        if (token && user && isLogged) {
            setUser(JSON.parse(user));
            setToken(token);
            setIsLogged(true);
            setOrders(JSON.parse(orders));
            setShoppingCart(JSON.parse(shoppingCart));

            return;
        }

        return navigate('/login');
    }, []);

    async function signIn(email_l, password_l) {
        const response = await auth.signIn(email_l, password_l);

        if (response.status !== 200) {
            return false;
        }

        const { token, user } = response.data;
        const { id, firstName, lastName, email, birthday, cpf, phone, profilePictureUrl, adresses, mainAddress } = user;

        setUser({ id: id, firstName: firstName, lastName: lastName, email: email, birthday: birthday, cpf: cpf, phone: phone, profilePictureUrl: profilePictureUrl, adresses: adresses, mainAddress: mainAddress });
        setToken(token);
        setIsLogged(true);

        localStorage.setItem('app-mercado-unido-logged', true);
        localStorage.setItem('app-mercado-unido-token', token);
        localStorage.setItem('app-mercado-unido-user', JSON.stringify({
            id: id,
            firstName: firstName,
            lastName: lastName,
            email: email,
            birthday: birthday,
            cpf: cpf,
            phone: phone,
            profilePictureUrl: profilePictureUrl,
            adresses: adresses,
            mainAddress: mainAddress
        }));

        await getItensCart(user.id, token);
        await getOrders(token);

        return response;
    }

    async function signOut() {
        const response = await auth.signOut(token);

        if (response.status !== 200) {
            return false;
        }

        localStorage.removeItem('app-mercado-unido-logged');
        localStorage.removeItem('app-mercado-unido-token');
        localStorage.removeItem('app-mercado-unido-user');
        localStorage.removeItem('app-mercado-unido-orders');
        localStorage.removeItem('app-mercado-unido-cart');

        setUser(null);
        setToken(null);
        setIsLogged(false);
        setOrders(null);
        setShoppingCart(null);

        return true;
    }

    async function getItensCart(id, token) {
        const response = await cart.getItens(id, token)

        if (response.status !== 200) return false;

        setShoppingCart(response.data.data);
        localStorage.setItem('app-mercado-unido-cart', JSON.stringify(response.data.data));

        return response;
    }

    async function getOrders(token) {
        const response = await order.get(token);

        if (response.status !== 200) return false;

        setOrders(response.data.data);
        localStorage.setItem('app-mercado-unido-orders', JSON.stringify(response.data.data));
    }

    async function addItemCart(quantity, price, id_offer) {
        const response = await cart.addItem(quantity, price, user.id, id_offer, token);

        if (response.status !== 201) {
            return false;
        }

        return response;
    }

    async function deleteItemCart(id) {
        const response = await cart.deleteItem(id, token);

        if (response.status !== 200) {
            return false;
        }

        setShoppingCart(shoppingCart.filter(item => item.id !== id));
        localStorage.setItem('app-mercado-unido-cart', JSON.stringify(shoppingCart.filter(item => item.id !== id)));

        return true;
    }

    async function getUserById(idUser, token) {
        const response = await users.get(idUser, token);

        if (response.status !== 200) {
            return false;
        }

        const { user } = response.data;
        const { id, firstName, lastName, email, birthday, cpf, phone, profilePictureUrl, adresses, mainAddress } = user;

        setUser({ id: id, firstName: firstName, lastName: lastName, email: email, birthday: birthday, cpf: cpf, phone: phone, profilePictureUrl: profilePictureUrl, adresses: adresses, mainAddress: mainAddress });
    
        localStorage.setItem('app-mercado-unido-user', JSON.stringify({
            id: id,
            firstName: firstName,
            lastName: lastName,
            email: email,
            birthday: birthday,
            cpf: cpf,
            phone: phone,
            profilePictureUrl: profilePictureUrl,
            adresses: adresses,
            mainAddress: mainAddress
        }));

        return response;
    }

    async function createAddress(parameters) {
        const response = await address.create(parameters);

        return (response.status === 201) ? response : false;
    }

    async function alterPassword(parameters) {
        const response = await users.alterPassword(parameters);

        return (response.status === 200) ? response : false;
    }

    const [ isOpenAlterPasswordModal, setIsOpenAlterPasswordModal ] = useState(false);

    const context = {
        signIn, signOut, addItemCart, deleteItemCart, getOrders, getItensCart,
        createAddress, getUserById, alterPassword,
        token, setToken,
        user, setUser,
        mainAddress, setMainAddress,
        shoppingCart, setShoppingCart,
        isLogged, setIsLogged,
        orders, setOrders,
        idCart, setIdCart,
        checkoutItem, setCheckoutItem,
        isOpenAlterPasswordModal, setIsOpenAlterPasswordModal,
    };

    return (
        <AuthContext.Provider value={context}>
            {children}
        </AuthContext.Provider>
    );
}