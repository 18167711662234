import axios from 'axios';
import { url } from './api';

export const insert = async (fantasyName, businessName, responsibleName, email, phone, cnpj, website, type, soldAnotherMarketplace, observation) => {
    try {
        const response = await axios.post(url + `/stores/sell-here`, {
            fantasyName: fantasyName, 
            businessName: businessName, 
            responsibleName: responsibleName, 
            email: email, 
            phone: phone, 
            cnpj: cnpj, 
            website: website, 
            type: type, 
            soldAnotherMarketplace: (soldAnotherMarketplace === "true") ? 1 : 0, 
            observation: observation,
        });

        return (response.status === 201) ? response : false;
    } catch (error) {
        console.log(error);

        return false;
    }
}