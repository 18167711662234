import axios from 'axios';
import { url } from './api';

export const get = async () => {
    try {
        const response = await axios.get(url + `/sub-categorys/active`);

        return (response.status === 200) ? response : false;
    } catch (error) {
        console.log(error);

        return false;
    }
}