import axios from 'axios';
import { url } from './api';

export const get = async (id, token) => {
    try {
        const response = await axios.get(url + `/user/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        return (response.status === 200) ? response : false;
    } catch (error) {
        console.log(error);

        return false;
    }
}

export const alterPassword = async (parameters) => {
    try {
        const response = await axios.put(url + `/users/alter-password/${parameters.idUser}`, {
            currentPassword: parameters.currentPassword,
            newPassword: parameters.newPassword,
            confirmNewPassword: parameters.confirmNewPassword,
        }, {
            headers: {
                'Authorization': `Bearer ${parameters.token}`
            }
        });

        return (response.status === 200) ? response : false;
    } catch (error) {
        console.log(error);

        return false;
    }
}