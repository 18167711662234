import React, { useContext } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import SaleSection from '../components/SaleSection';
import SubCategorySearchResult from '../components/SubCategorySearchResult';
import MainContext from '../context/MainContext';

function SubCategorySearchPage() {
  const {
    subCategorySearch, offers
  } = useContext(MainContext);

  const filteredItems = offers.filter(item => item.product.id_sub_category === subCategorySearch.idSubCategory);

  return (
    <>
      <Header />
      <section className="bg-background-gray w-full">
        <article className='mx-auto w-full text-center'>
          {/*<div
            className="bg-cover bg-center h-auto text-white py-24 px-10 object-fill"
            style={{
              backgroundImage: `url(${subCategorySearch.banner})`,
            }}
          >
            <div className="md:w-1/2">
              <p className="font-bold text-sm uppercase"></p>
              <p className="text-3xl font-bold"></p>
              <p className="text-2xl mb-10 leading-none"></p>
              <a
                href="#"
                className=" py-4 px-8 text-white font-bold uppercase text-xs rounded hover:bg-gray-200 hover:text-gray-800"
              >
                
              </a>
            </div>
          </div>*/}
          <h4 className='pt-[55px] text-[20px] text-gray-600'>
            Todos os Produtos de <strong>"{subCategorySearch.category}"</strong> &gt;&gt; <strong>"{subCategorySearch.subCategory}"</strong>
          </h4>
          <p className='text-gray-600 text-[15px] pt-[8px]'>
            {filteredItems.length} Produtos encontrados
          </p>
        </article>
        <SubCategorySearchResult
          filteredItems={filteredItems}
        />
        <SaleSection category="ending_sales" title="Ofertas mais próximas de expirar" />
        <SaleSection category="last_bought" title="Últimos produtos comprados" />
      </section>
      <Footer />
    </>

  )
}

export default SubCategorySearchPage;