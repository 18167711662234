import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Main from './pages/Main';
import TermsOfUse from './pages/TermsOfUse';
import PrivacyPolicies from './pages/PrivacyPolicies';
import Login from './pages/Login';
import ProductPage from './pages/ProductPage';
import SearchPage from './pages/SearchPage';
import CategorySearchPage from './pages/CategorySearchPage';
import SubCategorySearchPage from './pages/SubCategorySearchPage';
import HelpCenter from './pages/HelpCenter';
import FinishOrder from './pages/FinishOrder';
import SellHere from './pages/SellHere';
import CreateAccount from './pages/CreateAccount';
import Profile from './pages/Profile';
import SocialLogin from './pages/SocialLogin';
import ScrollToTop from './utils/scrollToTop';
import MyCart from './pages/MyCart';
import Payment from './pages/Payment';
import QrCodePix from './pages/QrCodePix';
import MyOrders from './pages/MyOrders';
import Boleto from './pages/Boleto';
import { useContext } from 'react';
import AuthContext from './context/AuthContext';

const Routing = () => {

  const { isLogged } = useContext(AuthContext);

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route element={<Main />} path="/" exact />
        <Route element={<SocialLogin />} path="/social-login" />
        <Route element={<FinishOrder />} path="/finishorder/:id" exact />
        <Route element={<Profile />} path="/profile" exact />
        <Route element={<MyCart />} path="/cart" exact />
        <Route element={<Payment />} path="/checkout/:id" exact />
        <Route element={<QrCodePix />} path="/payment/pix/:id" exact />
        <Route element={<Boleto />} path="/payment/boleto/:id" exact />
        <Route element={<MyOrders />} path="/orders" exact />
        <Route element={<Login />} path="/login" />
        <Route element={<CreateAccount />} path="/create-account" />
        <Route element={<ProductPage />} path="/product/:id" />
        <Route element={<SearchPage />} path="/search" />
        <Route element={<SubCategorySearchPage />} path="/search/sub-category/:id" />
        <Route element={<CategorySearchPage />} path="/search/category/:id" />
        <Route element={<TermsOfUse />} path="/termsofuse" exact />
        <Route element={<PrivacyPolicies />} path="/privacypolicies" exact />
        <Route element={<HelpCenter />} path="/helpcenter" exact />
        <Route element={<SellHere />} path="/sellhere" exact />
        <Route element={<FinishOrder />} path="/finishorder/:id" exact />
        <Route element={<CreateAccount />} path="/social-login/create-account" exact />
      </Routes>
    </>
  )
}

export default Routing;
