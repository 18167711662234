import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import MainContext from '../context/MainContext';
import { useEffect } from 'react';
import { IMaskInput } from 'react-imask';
import { useState } from 'react';
import Loader from "./../components/small_components/Loader";

function SellHere() {
  const navigate = useNavigate();
  const { getCategorys, category, insertAffiliateStore, insertAffiliateStoreCategory } = useContext(MainContext);

  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [hasError, setHasError] = useState({
    status: false,
    title: '',
    msg: ''
  });
  const [hasSuccess, setHasSuccess] = useState({
    status: false,
    title: '',
    msg: ''
  });

  useEffect(() => {
    async function getCategories() {
      await getCategorys();
    }

    getCategories();
  }, []);

  const handleCheckboxChange = (categoryId) => {
    if (selectedCategories.includes(categoryId)) {
      setSelectedCategories(selectedCategories.filter(id => id !== categoryId));
    } else {
      setSelectedCategories([...selectedCategories, categoryId]);
    }
  };

  const handleClickFormStore = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    try {
      const response = await insertAffiliateStore(
        fantasyName, businessName, responsibleName, email, phone, cnpj,
        website, type, soldAnotherMarketplace, observation
      );

      if (response.status === 201) {
        const responseAffiliateCategory = await insertAffiliateStoreCategory(response.data.data.id, selectedCategories);

        if (responseAffiliateCategory.status === 200) {
          setHasSuccess({
            status: true,
            title: "Seus dados foram enviados!",
            msg: "Seus dados estão em analise e logo você receberá um retorno sobre sua solicitação."
          })
        } else {
          setHasError({
            status: true,
            title: "Ocorreu um erro inesperado",
            msg: "Sua solicitação foi enviada com sucesso, porém não conseguimos enviar os dados do produtos que trabalham. Não se preocupe, deixe para mais tarde."
          })
        }
      } else {
        setHasError({
          status: true,
          title: "Ocorreu um erro inesperado",
          msg: "Não foi possível concluir sua solicitação de afiliação, tente novamente mais tarde."
        })
      }
    } catch (error) {
      console.log('Erro: ' + error);

      setHasError({
        status: true,
        title: "Ocorreu um erro inesperado",
        msg: "Não foi possível concluir sua solicitação de afiliação, tente novamente mais tarde."
      })
    } finally {
      setIsLoading(false);
      window.scrollTo(0, 0);
    }
  }

  const [fantasyName, setFantasyName] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [website, setWebsite] = useState('');
  const [responsibleName, setResponsibleName] = useState('');
  const [email, setEmail] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [phone, setPhone] = useState('');
  const [type, setType] = useState('');
  const [soldAnotherMarketplace, setSoldAnotherMarketplace] = useState('');
  const [observation, setObservation] = useState('');

  return (
    <>
      <Header />
      <section className="bg-background-gray py-10">
        {
          (isLoading) && (
            <Loader />
          )
        }
        {
          (hasSuccess.status) ? (
            <div class="mx-auto bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md max-w-screen-lg mb-[10px]" role="alert">
              <div class="flex">
                <div class="py-1"><svg class="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg></div>
                <div>
                  <p class="font-bold">{hasSuccess.title}</p>
                  <p class="text-sm">{hasSuccess.msg}</p>
                </div>
              </div>
            </div>
          ) : (
            (hasError.status) && (
              <div class="mx-auto bg-red-100 border-t-4 border-red-500 rounded-b text-red-900 px-4 py-3 shadow-md max-w-screen-lg mb-[10px]" role="alert">
                <div class="flex">
                  <div class="py-1"><svg class="fill-current h-6 w-6 text-red-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg></div>
                  <div>
                    <p class="font-bold">{hasError.title}</p>
                    <p class="text-sm">{hasError.msg}</p>
                  </div>
                </div>
              </div>
            )
          )
        }
        <article className="bg-white mx-auto rounded-md w-5/6 shadow-md px-8 py-12 lg:pb-[130px] lg:pt-[38px] lg:px-[90px] max-w-screen-lg lg:pr-[277px]">
          <div className='flex flex-row items-center hover:cursor-pointer hover:opacity-80' onClick={() => navigate(-1)}>
            <AiOutlineArrowLeft className='text-primary-blue font-bold' />
            <p className='px-2 text-primary-blue underline'>Voltar</p>
          </div>
          <h3 className="text-primary-blue text-left w-full font-bold text-[35px] py-[10px]">Venda no Mercado Unido</h3>
          <p className='text-gray-500 text-[18px] pb-[30px]'>
            Insira suas informações abaixo para fazer parte deste grande negócio
          </p>
          <div className="flex-auto pb-[30px]">
            <div className="mt-6 grid grid-cols-1 gap-y-[16px] gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-[16px] font-medium text-primary-blue">
                  Nome Fantasia
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    autoComplete="given-name"
                    value={fantasyName}
                    onChange={(e) => setFantasyName(e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-tertiary-blue focus:ring-tertiary-blue sm:text-[16px]"
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-[16px] font-medium text-primary-blue">
                  Razão Social
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    autoComplete="given-name"
                    value={businessName}
                    onChange={(e) => setBusinessName(e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-tertiary-blue focus:ring-tertiary-blue sm:text-[16px]"
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label htmlFor="last-name" className="block text-[16px] font-medium text-primary-blue">
                  Site da Empresa
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    autoComplete="family-name"
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-tertiary-blue focus:ring-tertiary-blue sm:text-[16px]"
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-[16px] font-medium text-primary-blue">
                  Nome do responsável
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    autoComplete="given-name"
                    value={responsibleName}
                    onChange={(e) => setResponsibleName(e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-tertiary-blue focus:ring-tertiary-blue sm:text-[16px]"
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label htmlFor="last-name" className="block text-[16px] font-medium text-primary-blue">
                  Email
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    autoComplete="family-name"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-tertiary-blue focus:ring-tertiary-blue sm:text-[16px]"
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label htmlFor="first-name" className="block text-[16px] font-medium text-primary-blue">
                  CNPJ
                </label>
                <div className="mt-1">
                  <IMaskInput
                    mask="00.000.000/0000-00"
                    type="text"
                    name="first-name"
                    id="first-name"
                    autoComplete="given-name"
                    value={cnpj}
                    onChange={(e) => setCnpj(e.target.value.replace(/\D/g, ''))}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-tertiary-blue focus:ring-tertiary-blue sm:text-[16px]"
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <label htmlFor="last-name" className="block text-[16px] font-medium text-primary-blue">
                  Telefone (com DDD)
                </label>
                <div className="mt-1">
                  <IMaskInput
                    mask="(00) 00000-0000"
                    type="text"
                    name="last-name"
                    id="last-name"
                    autoComplete="family-name"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value.replace(/\D/g, ''))}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-tertiary-blue focus:ring-tertiary-blue sm:text-[16px]"
                  />
                </div>
              </div>
              <div className='sm:col-span-3'>
                <label htmlFor="country" className="block text-[16px] font-medium text-primary-blue">
                  Tipo de fornecedor
                </label>
                <div className="mt-1">
                  <select
                    id="country"
                    name="country"
                    autoComplete="country-name"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-tertiary-blue focus:ring-tertiary-blue sm:text-[16px]"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                  >
                    <option value="" className='text-gray-400 pointer-events-none' disabled selected hidden>Selecione uma opção</option>
                    <option value="FORNECEDOR">Fornecedor</option>
                    <option value="DISTRIBUIDOR">Distribuidor</option>
                  </select>
                </div>
              </div>
              <div className='sm:col-span-3'>
                <label htmlFor="country" className="block text-[16px] font-medium text-primary-blue">
                  Já vendem em outros marketplaces?
                </label>
                <div className="mt-1">
                  <select
                    value={soldAnotherMarketplace}
                    onChange={(e) => setSoldAnotherMarketplace(e.target.value)}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-tertiary-blue focus:ring-tertiary-blue sm:text-[16px]"
                  >
                    <option value="" className='text-gray-400 pointer-events-none' disabled selected hidden>Selecione uma opção</option>
                    <option value={true}>Sim</option>
                    <option value={false}>Não</option>
                  </select>
                </div>
              </div>

              <div className='sm:col-span-3 sm:col-start-1'>
                <label htmlFor="country" className="block text-[16px] font-medium text-primary-blue">
                  Que tipo de produto sua empresa oferece?
                </label>
                <div className="mt-1 flex flex-col">
                  {
                    (category === null || category.length <= 0 || category === undefined) ? (
                      <label>Não há nenhuma categoria cadastrada.</label>
                    ) : (
                      category.map(category => (
                        <div className='flex flex-row items-center pl-[10px] pt-[10px]'>
                          <input
                            type="checkbox"
                            name={category.id}
                            id={category.id}
                            className='pr-[5px] h-4 w-4 rounded border-gray-300 text-primary-red focus:ring-0'
                            onChange={() => handleCheckboxChange(category.id)}
                            checked={selectedCategories.includes(category.id)}
                          />
                          <label htmlFor={category.id} className='pl-[10px]'>
                            {category.name}
                          </label>
                        </div>
                      ))
                    )
                  }
                </div>
              </div>

              <div className="sm:col-span-3">
                <label htmlFor="observations" className="block text-[16px] font-medium text-primary-blue">
                  Observações
                </label>
                <div className='mt-1'>
                  <textarea
                    id="observations"
                    name="observations"
                    rows={5}
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-tertiary-blue focus:ring-tertiary-blue sm:text-[16px]"
                    value={observation}
                    onChange={(e) => setObservation(e.target.value)}
                  />
                </div>
              </div>
              <div className="sm:col-span-3 sm:col-start-1 mt-[70px]">
                <button
                  className="mx-1 font-bold text-[16px] w-full border border-white bg-primary-blue rounded-xl px-4 py-[18px] text-white hover:bg-tertiary-blue hover:border-tertiary-blue duration-150"
                  onClick={(e) => handleClickFormStore(e)}
                >
                  Enviar
                </button>
              </div>
            </div>
          </div>
        </article>
      </section>
      <Footer />
    </>
  )
}

export default SellHere;