import axios from 'axios';
import { url } from './api';

export const insert = async (id_affiliate_store, id_categorys) => {
    try {
        const response = await axios.post(url + `/store/categorys`, {
            id_affiliate_store: id_affiliate_store,
            id_category: id_categorys
        });

        return (response.status === 200) ? response : false;
    } catch (error) {
        console.log(error);

        return false;
    }
}