import axios from 'axios';
import { url } from './api';
import { addBusinessDays } from './../utils/format';

export const generateQrCodePix = async (
    token, name_customer, email_customer, tax_id_customer, area_phone, number_phone, name_item, quantity_item, unit_amount_item,
    amount_qr_code, street_shipping, number_shipping, complement_shipping, locality_shipping, city_shipping, region_code_shipping,
    postal_code_shipping, id_product, id_cart, id_offer
) => {
    try {
        const response = await axios.post(url + '/charge/pix', {
            name_customer: name_customer,
            email_customer: email_customer,
            tax_id_customer: tax_id_customer,
            area_phone: area_phone,
            number_phone: number_phone,
            name_item: name_item,
            quantity_item: quantity_item,
            unit_amount_item: unit_amount_item,
            amount_qr_code: amount_qr_code,
            street_shipping: street_shipping,
            number_shipping: number_shipping,
            complement_shipping: complement_shipping,
            locality_shipping: locality_shipping,
            city_shipping: city_shipping,
            region_code_shipping: region_code_shipping,
            postal_code_shipping: postal_code_shipping,
            id_product: id_product,
            id_cart: id_cart,
            id_offer: id_offer,
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        return response;
    } catch (error) {
        console.log(error);

        return false;
    }
}

export const generateBoleto = async (
    token, value, name_customer, email_customer, tax_id_customer, street_shipping, number_shipping,
    locality_shipping, city_shipping, region_code_shipping, postal_code_shipping, id_product, id_cart, id_offer
) => {
    try {
        // Obtendo a data atual
        const currentDate = new Date();
        // Adicionando 2 dias úteis
        const newDate = addBusinessDays(currentDate, 2);
        // Formatação da nova data para o formato "YYYY-MM-DD"
        const formattedDueDate = newDate.toISOString().split('T')[0];

        const response = await axios.post(url + '/charge/boleto', {
            value: value,
            due_date: formattedDueDate,
            name_customer: name_customer,
            email_customer: email_customer,
            tax_id_customer: tax_id_customer,
            street_shipping: street_shipping,
            number_shipping: number_shipping,
            locality_shipping: locality_shipping,
            city_shipping: city_shipping,
            region_code_shipping: region_code_shipping,
            postal_code_shipping: postal_code_shipping,
            id_product: id_product,
            id_cart: id_cart,
            id_offer: id_offer,
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        return response;
    } catch (error) {
        console.log(error);

        return false;
    }
}

export const payWithCreditCard = async (token, value, installments, number, exp_month, exp_year, security_code, holder, id_cart, id_offer, id_product) => {
    try {
        const response = await axios.post(url + '/charge/credit/card', {
            description: 'Compra no mercado unido.',
            value: value,
            installments: installments,
            number: number,
            exp_month: exp_month,
            exp_year: exp_year,
            security_code: security_code,
            holder: holder,
            id_cart: id_cart,
            id_offer: id_offer,
            id_product: id_product
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        return response;
    } catch (error) {
        console.log(error);

        return false;
    }
}