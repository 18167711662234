import axios from 'axios';
import { url } from './api';

export const get = async (token) => {
    try {
        const response = await axios.get(url + `/transactions`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        return (response.status === 200) ? response : false;
    } catch (error) {
        console.log(error);

        return false;
    }
}