import { useContext } from 'react'
import MainContext from '../context/MainContext';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function SideMenu() {
  const {
    menu, setMenu,
    subCategory, setSubCategorySearch
  } = useContext(MainContext);

  const navigate = useNavigate();

  // ======= Montando um objeto mais fácil para usar no MAP =======
  const categories = {};

  subCategory.forEach(obj => {
    const categoryId = obj.category.id;
    const categoryName = obj.category.name;
    const categoryActive = obj.category.active;
    const categoryBanner = obj.category.promotionalBanner

    if (!categories[categoryId]) {
      categories[categoryId] = {
        id: categoryId,
        name: categoryName,
        active: categoryActive,
        banner: categoryBanner,
        subCategories: [],
      };
    }

    categories[categoryId].subCategories.push({
      id: obj.id,
      name: obj.name,
      length: obj.products.length,
      active: obj.active,
    });
  });

  const handleClickSubCategoryOffer = (e, idSubCategory, subCategory, category, banner) => {
    e.preventDefault();

    setSubCategorySearch({
      idSubCategory: idSubCategory,
      subCategory: subCategory,
      category: category,
      banner: banner,
    })

    navigate(`/search/sub-category/${idSubCategory}`);
    setMenu(!menu);
  }

  const handleClickCategoryOffer = (e, idCategory, category) => {
    e.preventDefault();

    setSubCategorySearch({
      idCategory: idCategory,
      category: category
    })

    navigate(`/search/category/${idCategory}`);
    setMenu(!menu);
  }

  return (
    <>
      {/* MOBILE */}
      <section id="side-menu" className="ease-in-out absolute lg:hidden top-0 left-0 h-screen w-full z-50 bg-white px-5 py-12 flex flex-col space-y-5 text-white duration-150 overflow-y-hidden">
        <div className="flex flex-row items-center justify-between text-gray-600 border-b border-gray-600 pb-[21.5px] mb-[21.5px]">
          <h2 className="font-bold ml-3 text-lg">Departamentos</h2>
          <button onClick={() => setMenu(!menu)}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
              <path strokeLinecap="round" strokeLinejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </button>
        </div>
        <article className="text-left text-gray-600 overflow-y-auto">
          {
            Object.values(categories).map(category => (
              (category.active) ? (
                <div className="pr-[180px]" key={category.id}>
                  <h3 onClick={(e) => handleClickCategoryOffer(e, category.id, category.name)} value={category.id} className="font-bold pb-[15px]">{category.name}</h3>
                  <ul className='pb-[15px]'>
                    {category.subCategories.map(subCategory => (
                      (subCategory.active) ? (
                        <Link key={subCategory.id} to={`/search/sub-category/${subCategory.id}`}>
                          <li
                            className={classNames('hover:text-primary-blue duration-150 hover:scale-110 py-[3.5px]')} key={subCategory.id}
                            value={subCategory.id}
                            onClick={(e) => handleClickSubCategoryOffer(e, subCategory.id, subCategory.name, category.name, category.banner)}
                          >
                            <a href="/">{subCategory.name}</a>
                          </li>
                        </Link>
                      ) : (
                        null
                      )
                    ))}
                  </ul>
                </div>
              ) : (
                null
              )
            ))
          }
        </article>
      </section>

      {/* DESKTOP */}

      {/* BLACK BG FOR DESKTOP */}
      <div onClick={() => setMenu(!menu)} className="hidden lg:block absolute top-0 left-auto h-screen w-full opacity-50 z-30 bg-black p-5 pb-0
      flex flex-col space-y-5 duration-150"></div>

      <section className="hidden lg:block absolute inset-x-0 top-16 w-[891px] h-[517px] z-50 bg-white pt-[49px] pl-[70px] pb-[61px] pr-[45px]
      flex flex-col space-y-5 duration-150 mt-1 rounded-b-[25px] mx-auto ease-in-out">
        <article className="flex flex-col flex-wrap content-start text-left text-gray-600 max-h-[407px] gap-y-[47px]">
          {
            Object.values(categories).map(category => (
              (category.active) ? (
                <div className="pr-[120px]" key={category.id}>
                  <h3 onClick={(e) => handleClickCategoryOffer(e, category.id, category.name)} value={category.id} className="font-bold pb-[15px]">{category.name}</h3>
                  <ul className='pb-[15px]'>
                    {category.subCategories.map(subCategory => (
                      (subCategory.length <= 0) ? (
                        <li
                          className={'opacity-50'}
                          key={subCategory.id}
                        >
                          <a className={'cursor-default'}>{subCategory.name}</a>
                        </li>
                      ) : (
                        <Link key={subCategory.id} to={`/search/sub-category/${subCategory.id}`}>
                          <li
                            className={'hover:text-primary-blue duration-150 hover:scale-110 py-[3.5px]'}
                            key={subCategory.id}
                            onClick={(e) => handleClickSubCategoryOffer(e, subCategory.id, subCategory.name, category.name, category.banner)}
                          >
                            <a href="/">{subCategory.name}</a>
                          </li>
                        </Link>
                      )
                    ))}
                  </ul>
                </div>
              ) : (
                null
              )
            ))
          }
        </article>
        <button className="text-gray-500 absolute right-8 bottom-4 hover:scale-110 hover:text-primary-red duration-150" onClick={() => setMenu(!menu)}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-7 w-7" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </button>
      </section>

    </>


  )
}